import React from 'react'
import {
  Flex,
  Container,
  FlexProps,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import Header from './Header'
import Footer from './Footer'

interface LayoutProps extends FlexProps {
  isFullWidth?: boolean
}

const Layout: React.FC<LayoutProps> = ({
  children,
  isFullWidth = false,
  ...props
}) => {
  return (
    <Flex
      direction="column"
      bg={mode('gray.50', 'gray.800')}
      minH="100vh"
      {...props}
    >
      <Header />
      <Container
        maxW={isFullWidth ? 'full' : '7xl'}
        as="main"
        pt={{ base: 14, md: 16 }}
        minH="100vh"
        p={isFullWidth ? 0 : undefined}
      >
        {children}
      </Container>
      <Footer />
    </Flex>
  )
}

export default Layout
