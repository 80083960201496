import { Flex, HStack, Button, Divider, Center } from '@chakra-ui/react'
import * as React from 'react'
import { Logo } from '../Logo'
import { MobileHamburgerMenu } from './MobileHamburgerMenu'
import { NavMenu } from './NavMenu'
import { useMobileMenuState } from './useMobileMenuState'
import { Link } from 'gatsby'

const Header = () => {
  const { isMenuOpen, toggle } = useMobileMenuState()

  return (
    <Flex
      align="center"
      color="brand"
      w="full"
      minH="16"
      as="header"
      backgroundColor="white"
      flexDirection="column"
      position="fixed"
      zIndex={999}
    >
      <Flex justify="space-between" align="center" w="full" p="4">
        <MobileHamburgerMenu onClick={toggle} isOpen={isMenuOpen} />
        <NavMenu.Mobile isOpen={isMenuOpen} />
        {/* Desktop Logo placement */}
        <Link to="/">
          <Logo
            display={{ base: 'none', lg: 'block' }}
            flexShrink={0}
            marginEnd="10"
          />
        </Link>
        {/* Desktop Navigation Menu */}
        <NavMenu.Desktop />
        {/* Mobile Logo placement */}
        <Center
          flex={{ base: '1', lg: '0' }}
          display={{ lg: 'none' }}
          as={Link}
          to="/"
        >
          <Logo />
        </Center>
        <HStack spacing="10" display={{ base: 'none', lg: 'block' }}>
          {/* <Button variant="link" as="a" href='https://www.google.com/' target='_blank'>
            Login
          </Button>
          <Button as="a" href='https://www.google.com/' target='_blank'>
            Sign up</Button> */}
          <Button as={Link} to="/#contact">
            Request Demo
          </Button>
        </HStack>
      </Flex>
      <Divider />
    </Flex>
  )
}

export default Header
