import {
  Box,
  Divider,
  Flex,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import { Logo } from '../Logo'
import { SocialButton } from './SocialButton'
import { links, socialLinks } from './_data'
import { Copyright } from './Copyright'

const Footer = () => {
  return (
    <Box as="footer" color={mode('black', 'gray.100')} py="64px" bgColor={mode('#FFFFFF', 'gray.800')}>
      <Box maxW="7xl" px="8" mx="auto">
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          justify="space-between"
          pb="8"
          align="flex-start"
          id="top"
        >
          <Box paddingEnd="12" mb={{ base: '10', lg: 0 }}>
            <Logo color="white" h="6" />
            <HStack spacing="4" mt="8" as="ul">
              {socialLinks.map((link, idx) => (
                <SocialButton
                  key={idx}
                  href={link.href}
                  target='_blank'
                  color={mode('gray.800', 'gray.100')}
                  bgColor={mode('gray.200', 'gray.600')}>
                  <Box srOnly>{link.label}</Box>
                  {link.icon}
                </SocialButton>
              ))}
            </HStack>
          </Box>
          <SimpleGrid
            w="full"
            maxW={{ base: 'unset', lg: '2xl' }}
            columns={{ base: 3, lg: 3 }}
            spacing={{ base: '9', md: '3' }}
            fontSize="sm"
          >
            {links.map((group, idx) => (
              <Box key={idx}>
                <Text fontWeight="bold" mb="4">
                  {group.title}
                </Text>
                <Stack as="ul" listStyleType="none">
                  {group.links.map((link, idx) => (
                    <Box as="li" key={idx}>
                      <Box
                        as="a"
                        href={link.href}
                        _hover={{ textDecor: 'underline' }}
                      >
                        {link.label}
                        {link.badge && (
                          <Box as="span" marginStart="2">
                            {link.badge}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  ))}
                </Stack>
              </Box>
            ))}
          </SimpleGrid>
        </Flex>
        <Divider my="10" borderColor="gray.200" />
        <Copyright alignSelf={{ base: 'center', sm: 'start' }} />
      </Box>
    </Box>
  )
}

export default Footer
