import {
  Box,
  HStack,
  useToken,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import { Link } from 'gatsby'

interface NavItemProps {
  href?: string
  label: string
}

interface DesktopNavItemProps extends NavItemProps {
  icon?: React.ReactNode
}

const DesktopNavItem = (props: DesktopNavItemProps) => {
  const { icon, label, href = '#' } = props
  const [brand500] = useToken('colors', ['brand.500'])
  return (
    <HStack
      as={Link}
      to={href}
      spacing="2"
      px="3"
      py="2"
      rounded="md"
      transition="all 0.2s"
      color={mode('gray.800', 'gray.400')}
      _hover={{ color: 'brand.500' }}
      activeStyle={{ color: brand500 }}
    >
      {icon && (
        <Box aria-hidden fontSize="md">
          {icon}
        </Box>
      )}
      <Box fontWeight="semibold">{label}</Box>
    </HStack>
  )
}

const MobileNavItem = (props: NavItemProps) => {
  const { label, href = '#' } = props
  const [brand500] = useToken('colors', ['brand.500'])
  return (
    <Box
      as={Link}
      display="block"
      to={href}
      px="3"
      py="3"
      color="gray.800"
      rounded="md"
      fontWeight="semibold"
      _hover={{ color: 'brand.500' }}
      activeStyle={{ color: brand500 }}
    >
      {label}
    </Box>
  )
}

export const NavItem = {
  Desktop: DesktopNavItem,
  Mobile: MobileNavItem,
}
