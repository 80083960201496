import { Box, Flex, HStack } from '@chakra-ui/react'
import * as React from 'react'
import { NavItem } from './NavItem'

type Item = {
  label: string
  to: string
}

const items: Item[] = [
  {
    label: 'Home',
    to: '/',
  },
  {
    label: 'Features',
    to: '/#features',
  },
  {
    label: 'Use Case',
    to: '/#use-case',
  },
  {
    label: 'Pricing',
    to: '/#pricing',
  },
  {
    label: 'Blog',
    to: '/blog',
  },
]

const MobileNavMenu = (props: { isOpen?: boolean }) => {
  const { isOpen } = props

  return (
    <Flex
      hidden={!isOpen}
      as="nav"
      direction="column"
      bg="white"
      position="fixed"
      height="calc(100vh - 4rem)"
      top="16"
      pt="4"
      insetX="0"
      zIndex={10}
      w="full"
    >
      <Box px="4">
        {items.map((i) => (
          <NavItem.Mobile label={i.label} href={i.to} key={i.to} />
        ))}
      </Box>
    </Flex>
  )
}

const DesktopNavMenu = () => (
  <HStack
    spacing="3"
    flex="1"
    display={{ base: 'none', lg: 'flex' }}
    justifyContent="center"
  >
    {items.map((i) => (
      <NavItem.Desktop label={i.label} href={i.to} key={i.to} />
    ))}
  </HStack>
)

export const NavMenu = {
  Mobile: MobileNavMenu,
  Desktop: DesktopNavMenu,
}
