import * as React from 'react'
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'

export interface LinkGroup {
  title: string
  links: Array<{
    label: string
    href: string
    badge?: React.ReactElement
  }>
}

export const links: LinkGroup[] = [
  {
    title: 'Company',
    links: [
      { label: 'Our story', href: '/ourStory' },
      {
        label: 'Careers',
        href: '#',
      },
      { label: 'Press', href: '#' },
      { label: 'FAQ', href: '#' },
    ],
  },
  {
    title: 'Resources',
    links: [
      { label: 'Blog', href: '/blog' },
      {
        label: 'Integrations',
        href: 'https://walawong.notion.site/walawong/0473798cf5f34ef9bf85fa5414e2c474?v=211ab5fd71de4c78a9f2e578c8541f3e',
      },
      { label: 'Privacy Policy', href: '/policy' },
      { label: 'Terms & Conditions', href: '/termsOfUse' },
    ],
  },
  {
    title: 'Contact',
    links: [
      { label: 'Email', href: 'mailto:support@walawong.com' },
      { label: 'Chat with us', href: 'https://discord.gg/KnRcmBGtU8' },
    ],
  },
]

interface SocialLink {
  label: string
  icon: React.ReactElement
  href: string
}

export const socialLinks: SocialLink[] = [
  {
    label: 'LinkedIn',
    icon: <FaLinkedin />,
    href: 'https://www.linkedin.com/company/walawong/',
  },
  { label: 'Github', icon: <FaGithub />, href: 'https://github.com/walawong' },
  {
    label: 'Twitter',
    icon: <FaTwitter />,
    href: 'https://twitter.com/walawong',
  },
]

type FooterLink = {
  label: string
  href: string
}

export const footerLinks: FooterLink[] = [
  { label: 'Terms of Service', href: '#' },
  { label: 'Privacy Policy', href: '#' },
  { label: 'Offer terms', href: '#' },
  { label: 'Legal notice', href: '#' },
  { label: 'Sitemap', href: '#' },
]
